/* src/App.css */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* Use Roboto for body text */
  background-color: #ffffff; /* White background */
  color: #000000; /* Black text */
}

.App {
  text-align: center;
}

.App-header {
  background-color: #333333; /* Dark gray for the header */
  padding: 20px;
  color: #ffffff; /* White text */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.logo {
  width: 50px;
  height: 50px; /* Set height to make it round */
  border-radius: 15%; /* Make the logo round */
  margin-right: 10px;
}

h1 {
  font-family: 'Lobster', cursive; /* Use the Lobster font */
  font-size: 2.5rem; /* Increase font size */
  letter-spacing: 2px; /* Add some spacing between letters */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Add a subtle shadow for depth */
  margin: 0; /* Remove default margin */
  color: #ffffff; /* Change to white for main heading */
}

h2 {
  font-family: 'Lobster', cursive; /* Use Lobster for section headings */
  font-size: 2rem; /* Increase font size for headings */
  margin: 20px 0; /* Add margin for spacing */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Add a subtle shadow for depth */
  color: #444444; /* Dark gray for section headings */
}

p {
  font-size: 1rem; /* Set font size for paragraphs */
  line-height: 1.6; /* Increase line height for readability */
  margin: 10px 0; /* Add margin for spacing */
  color: #666666; /* Medium gray for paragraphs */
}

.social-media {
  margin-top: 10px;
}

.social-icon {
  width: 30px; /* Set size for social icons */
  height: 30px; /* Set size for social icons */
  border-radius: 50%; /* Make icons round */
  margin: 0 10px;
  transition: transform 0.3s;
  /* Remove the grayscale filter to show original colors */
}

.social-icon:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.section-block {
  margin: 20px 0;
  padding: 20px;
  background: #f0f0f0; /* Light gray for section background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow images to wrap */
}

.gallery img {
  width: 15%; /* Smaller gallery images */
  margin: 10px; /* Space between images */
  border-radius: 8px; /* Rounded corners for images */
}

footer {
  background-color: #222222; /* Very dark gray for footer */
  padding: 20px;
  color: #ffffff; /* White text */
}

.subheader {
  font-family: 'Roboto', sans-serif; /* Use Roboto for better readability */
  font-weight: 400; /* Normal weight for readability */
  font-size: 1.2rem; /* Keep the size smaller than the header */
  margin: 5px 0; /* Add some margin for spacing */
  color: white; /* Match the header text color */
  text-align: center; /* Center align for consistency */
}

.image-section {
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

.header-image {
  width: 100%; /* Make the image take the full width of the container */
  height: 100%; /* Stretch the image to fill the height */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}